import React from 'react'
import { checkmed } from '../../../config/images.config'

const InnerCard = () => {
  return (
    <div className='bg-[#BAB1FF] w-[41vw] h-[500px] rounded-2xl p-2'>
      <div className='pt-14 flexCenter flex-col'>
        <h3 className='font-hammersmith text-[26px] text-white-10'>Close to Home Close To Your Heart</h3>
        <img
          src={checkmed}
          alt="Checkmed"
          className='h-[300px] w-[300px] mt-5 m-2'
        />
      </div>
    </div>
  )
}

export default InnerCard