import React from 'react';
import { EmailPassInp, SigninButton } from '../../../config/components.config';
import { email, visible } from '../../../config/images.config';

const FormCard = () => {
  return (
    <div className='max-container padding-container flexCenter mt-4 mb-4'>
      <div className='border-2 border-[#BAB1FF] w-[350px] h-[550px] lg:w-[400px] lg:h-[550px] rounded-3xl'>
        <div className='p-5 pl-10'>
          <h1 className='text-[24px] mt-5 text-black font-medium'>Nice to see you!</h1>
          <p className='mt-4 text-[#525252] text-[16px] font-normal'>Enter your email and password to sign in</p>

          <div className='pt-4'>
            <EmailPassInp
              label='Email'
              placeholder="Your Email Adress"
              icon={email}
            />

            <EmailPassInp
              label='Password'
              placeholder="Your password"
              icon={visible}
            />
          </div>
        </div>

        <div className='mt-10 flex flex-col flexCenter'>
          <SigninButton
            value="SIGN IN"
          />

          <div className='mt-4'>
            <p className='text-[15px] text-[#4B4B4B] font-extralight p-5 text-center'>By clicking ‘Sign In’ you agree to our website
              <a
                href="*"
                className='underline text-[16px] text-black'
              > Terms & conditions</a>
            </p>
          </div>

        </div>
      </div>
    </div>
  )
}

export default FormCard