import React from 'react'

const EmailPassInp = ({ label, placeholder, icon }) => {
  return (
    <div className='flex flex-col mt-4'>

      <label
        htmlFor=""
        className='text-black text-[17px]'>
        {label}</label>

      <input
        type="text"
        placeholder={placeholder}
        className='border-2 rounded-3xl border-[#BAB1FF] w-[270px] h-[40px] lg:w-[300px] lg:h-[45px] text-start pl-3 pr-10'
      />
    </div>
  )
}

export default EmailPassInp;