import React from 'react'
import { InnerCard } from '../../../config/components.config'

const MainCard = () => {
    return (
        <div className='bg-[#C5C0FC] w-1/2 max-h-fit min-h-screen hidden lg:flex lg:flex-col'>
            <p className='font-cambria font-semibold text-bold text-[18px] p-3 pr-5 w-fit mb-4'>CHECKMED</p>
            <div className='flex justify-center items-center'>
                <InnerCard />
            </div>
        </div>
    )
}

export default MainCard