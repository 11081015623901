import React from 'react';

const SigninButton = ({ value }) => {
  return (
    <button className='w-[250px] h-[40px] lg:w-[280px] lg:h-[40px] bg-gradient-to-r from-[#4F45B3] to-[#A59CD9]
     font-thin text-[14px] rounded-1xl text-white-10'>
      {value}
    </button>
  );
}

export default SigninButton;