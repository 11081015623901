import React from 'react';
import { FormCard, MainCard } from '../config/components.config.js';

const Login = () => {
  return (
    <div className="flex flex-col lg:flex-row">
      <p className='font-cambria font-semibold text-bold text-[18px] p-3 pr-5 w-fit mb-4 lg:hidden'>CHECKMED</p>
      <MainCard />
      <div className='flex justify-center items-center flex-grow'>
        <FormCard />
      </div>
    </div>
  );
}

export default Login;